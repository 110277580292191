import Alpine from "alpinejs";
import "focus-visible"; // Focus Visible Polyfill

// Internal Modules
import "./modules/nav";
import "./modules/theme";

// Instantiate Alpine.js
window.Alpine = Alpine;
Alpine.start();
